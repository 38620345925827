@import "src/styles/abstracts/mixins.scss";

.indexPageBlock10 {
  .__block-container {
    position: relative;
    .__reviews-wrapper {
      background: white;
      min-width: 0;
      border-radius: 8px;
      margin: 0 6px;
      @include md {
        margin: 0 24px;
      }
      .__image {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .__reviews-container {
        flex-direction: column;
        @include md {
          flex-direction: row;
        }
        .__review-after {
          max-width: 100%;
          @include md {
            max-width: 40%;
          }
        }
        .__divider-v {
          display: none;
          @include md {
            display: block;
          }
        }
        .__divider-h {
          display: block;
          @include md {
            display: none;
          }
        }
      }
    }
  }
  .__btn-default {
    display: none;
    @include md {
      display: flex;
    }
  }
  .__btns-bottom {
    display: flex;
    @include md {
      display: none;
    }
  }
}
