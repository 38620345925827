@import "src/styles/abstracts/mixins.scss";

.indexPageBlock7 {
  background: black;

  .__cards-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 105px;
    width: 100%;
    @include md {
      width: auto;
      flex-direction: row;
    }

    .__card {
      margin: 32px 0;
      @include md {
      margin: 32px;
        //margin: 0;
        max-width: 500px;
      }
      border-radius: 20px;
      border: 1px solid white;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #131313;
      max-width: 100%;
      width: 100%;
    }
  }
}
