@import "src/styles/abstracts/mixins.scss";

.indexPageBlock12 {
  .__block-container {
    position: relative;
    padding: 65px 0;

    .__image {
      padding: 32px;
      max-width: 100%;
      margin-bottom: 0;
      @include md {
        padding: 0;
        margin-bottom: -16px;
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 80%;
        z-index: 1;
      }
    }
    > * {
      z-index: 2;
      position: relative;
    }
  }
}
