@import "classes";
@import "src/styles/abstracts/mixins.scss";

:root {
  // COLORS
  //--color-primary: #121212;
  --color-primary: #ffce02;
  --color-tertiary: #f5f5f7;
  --color-primary-base: #5672ff;
  --color-layout-fill-base: #f5f5f5;
}

#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  //scroll-behavior: smooth;

  .App {
    width: 100%;
  }
}

@include md {
  * {
    scrollbar-color: var(--color-primary-base) var(--color-layout-fill-base);
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 4px;
    background-color: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-base);
  }
}
