.document__wrapper {
  max-width: 960px;
  padding: 24px 48px;
  margin: auto;

  h1 {
    font-size: 14pt !important;
    font-weight: 700 !important;
    text-align: center;
    margin-bottom: 24px !important;
  }

  h2 {
    font-size: 13pt !important;
    font-weight: 700 !important;
  }

  h3 {
    font-size: 13pt !important;
    margin-bottom: 24px !important;
  }

  span {
    //
  }

  p {
    font-size: 11pt !important;
    margin: 1.15em 0 !important;
  }

  a {
    color: var(--color-primary-base);

    &:hover {
      text-decoration: underline;
    }
  }
}
