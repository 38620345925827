@import "src/styles/abstracts/mixins.scss";

.indexPage {
  .__block-wrapper {
    padding: 12px 12px;
    //padding: 16px 48px;
    @include md {
      padding: 16px 48px;
    }
    .__block-container {
      max-width: 1200px;
      margin: auto;
    }
  }

  .chakra-button  {
    width: 100%;
    max-width: none;
    @include md {
      max-width: 360px;
    }
  }
}
