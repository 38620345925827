@import "src/styles/abstracts/mixins.scss";

.indexPageBlock2 {
  .__block-container {
    position: relative;
    h2 {
      padding-top: 105px;
    }

    .__cards-wrapper {
      margin-bottom: -16px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;
      background: white;
      .__card {
        padding: 30px;
      }
    }

    .__image {
      padding: 32px;
      max-width: 100%;
      margin-bottom: -65px;
      @include md {
        margin-bottom: 0;
        padding: 0;
        position: absolute;
        transform: translateY(-50%);
        top: calc(50% - 20px);
        right: 0;
        max-height: 80%;
        z-index: 1;
      }
    }
    > * {
      z-index: 2;
      position: relative;
    }
  }
}
