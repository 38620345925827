@import "src/styles/abstracts/mixins.scss";

.landingPageBlock13 {
  .__block-container {
    margin-top: 32px !important;
    display: flex;
    flex-direction: column;

    .__vc-logo__wrapper {
      max-width: 72px;
      //align-self: center;
      margin-left: 0;

      @include md {
        margin-left: 44px;
      }
    }

    @include md {
      flex-direction: row;
    }

    .__logo {
      width: 228px;
      margin-bottom: 16px;
    }

    .__info_block {
      display: flex;
      flex-direction: column;
      margin-left: 0;

      @include md {
        flex-direction: row;
        margin-left: 92px;
      }

      .__social-link {
        svg path {
          transition: all 0.25s;
        }
        &:nth-child(1):hover {
          svg path {
            fill: #ff0000;
          }
        }
        &:nth-child(2):hover {
          svg path {
            fill: #0088cc;
          }
        }
      }
    }
  }
}
