@import "src/styles/abstracts/mixins.scss";

.indexPageBlock6 {
  .blocks-columns {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }

    .__column {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include md {
        width: 50%;
      }
    }
  }

  .image__wrapper {
    display: flex;
    flex-direction: column;
    //border: 1px solid black;
    position: relative;
    z-index: 0;

    &::before {
      content: "";
      z-index: -1;
      border-radius: 8px;
      position: absolute;
      display: block;
      left: 8px;
      top: 8px;
      width: 100%;
      height: 100%;
      border: 1px solid black;
    }

    .image__wrapper-header {
      background: #f5f5f7;
      border-radius: 8px 8px 0 0;
      display: flex;
      padding: 8px;
      border: 1px solid black;

      .image__wrapper-header-button {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
        border: 1px solid black;

        &:nth-child(1) {
        background: red;
        }
        &:nth-child(2) {
          background: yellow;
        }
        &:nth-child(3) {
          background: green;
        }
      }
    }

    .image__wrapper-content {
      border-radius: 0 0 8px 8px;
      border: 1px solid black;
      border-top: none;
      background: white;
      overflow: hidden;
      position: relative;

      .__logo {
        background: white;
        //background: rgba(255, 255, 255, 0.2);
        //border: 1px solid white;
        //backdrop-filter: blur(2px);
        display: block;
        padding: 2px 2px;
        position: absolute;
        bottom: 4px;
        left: 4px;
        border-radius: 6px;
        height: 16px;
      }
    }
  }
}
