@import "src/styles/abstracts/mixins.scss";

.indexPageBlock8 {
  .__cards-wrapper {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    border: none;
    flex-direction: column;
    @include md {
      border: 1px solid black;
      flex-direction: row;
    }

    .__card {
      width: 100%;
      background: white;
      //border-right: none;
      border: 1px solid black;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      overflow: hidden;
      border-radius: 20px;
      @include md {
        border: none;
        border-radius: 0;
        width: calc(100% / 3);
        margin-bottom: 0 !important;
        &:not(:last-child) {
          border-right: 1px solid black;
        }
      }
      .__header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        flex-direction: column;
        text-align: center;
        padding: 12px;
        position: relative;
        .__period {
          position: absolute;
          bottom: 0;
          left: 50%;
          background: white;
          border-radius: 50px;
          border: 1px solid black;
          transform: translateX(-50%) translateY(50%);
          padding: 0 1em;
        }
      }
      .__info {
        padding: 15px 0;
        text-align: center;
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        background: #e8e8e8;

        &.__empty {
          display: none;
          @include md {
            opacity: 0;
            display: block;
          }
        }
      }
      .__list-wrapper {
        padding: 12px;
      }
    }
  }
}
