@import "src/styles/abstracts/mixins.scss";

.landingPageBlock1 {
  height: auto;

  img {
    height: unset;
    width: 128px;
  }

  button {
    margin-top: 12px;
    width: 100%;
  }

  .__block-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    > div {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }

  @include md {
    height: 96px;

    img {
      width: 256px;
      height: unset;
    }

    button {
      margin-top: 0;
      width: auto;
    }

    .__block-container {
      display: flex;
      align-items: center;
      flex-direction: row;

      > div {
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
