@import "src/styles/abstracts/mixins.scss";

.indexPageBlock9 {
  .__table {
    display: flex;
    border: none;
    border-radius: 0;
    overflow: hidden;
    @include md {
      border: 1px solid black;
      border-radius: 8px;
    }

    .__row {
      height: 3.5em;
      line-height: 1.15em;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0 24px;
      font-size: 14px;
      font-weight: bold;

      svg {
        transform: scale(0.75);
      }

      @include md {
        font-size: 18px;
        svg {
          transform: scale(1);
        }
      }

      &.__regular-price {
        //text-decoration: line-through;
        span {
          font-size: 18px;
          @include md {
            font-size: 20px;
          }
          text-align: center;
        }
      }

      &.__offer-price {
        background: var(--color-primary);
        span {
          font-size: 20px;
          @include md {
            font-size: 24px;
          }
          text-align: center;
        }
      }

      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }

    .__options-list {
      width: 55%;
      display: none;
      @include md {
        display: flex;
        flex-direction: column;
      }
    }

    .__plans-list {
      flex-grow: 1;
      //width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      @include md {
        flex-direction: row;
      }
      .__plan-wrapper {
        flex-grow: 1;
        border-radius: 8px;
        border-left: none;
        border: 1px solid black;
        overflow: hidden;
        &:not(:last-child) {
          margin-bottom: 48px;
        }
        @include md {
          overflow: visible;
          margin-bottom: 0 !important;
          border-radius: 0;
          border: none;
          border-left: 1px solid black;
        }

        .__row {
          justify-content: center;
          &:not(.__header) {
            justify-content: space-between;
            @include md {
              justify-content: center;
            }
          }

          .__m_visible {
            display: inline-block;
            @include md {
              display: none;
            }
          }

          &.__m_hidden {
            display: none;
            @include md {
              display: flex;
            }
          }

          svg {
            flex-shrink: 0;
            margin-left: 0.5em;
            @include md {
              margin-left: 0;
            }
          }
        }

        .__regular-price {
          span:not(.__m_visible) {
            text-decoration: line-through;
          }
          white-space: pre;
          //.__m_visible {
          //  text-decoration: none !important;
          //}
        }
        .__offer-price {
          white-space: pre;
        }

        &.__plan-standard {
          .__header {
            background: var(--color-primary);
          }
          .__regular-price {
            background: white;
            @include md {
              background: #e8e8e8;
            }
          }
        }
        &.__plan-premium {
          .__header {
            background: red;
            color: white;
          }
          .__regular-price {
            background: white;
            @include md {
              background: #e8e8e8;
            }
          }
        }
        &.__plan-vip {
          .__header {
            background: black;
            color: white;
          }
          .__row:not(.__header):nth-child(2) {
            background: white;
            @include md {
              background: #e8e8e8;
            }
          }
        }
      }
    }
  }
}
