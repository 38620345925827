.indexPageBlock11 {
  .accordion {
    padding: 12px 0;
    .chakra-accordion__item {
      .chakra-accordion__button {
        padding: 16px;
        font-size: 22px;
        font-weight: 900;
        &:hover {
          background: none;
        }
        svg {
          background: var(--color-primary);
          border-radius: 50%;
        }
      }
      .chakra-accordion__panel {
        font-size: 18px;
      }
      border-radius: 10px;
      border: 1px solid black;
    }
  }
}
